import { lazy } from "react";
import { Navigate } from "react-router";
import DepositWallet from "src/pages/userSide/financial/deposit";
import Ewallet from "src/pages/userSide/financial/ewallet";
import FundTransfer from "src/pages/userSide/financial/fundTransfer";
import Loadable from "../Loadable";
import useAuth from "src/hooks/useAuth";
import CouponReport from "src/pages/userSide/financial/coupon-report";

const AddCredit = Loadable(
  lazy(() => import("src/pages/userSide/financial/deposit/addCredit"))
);

const RequestPayout = Loadable(
  lazy(() => import("src/pages/userSide/financial/requestPayout/index"))
);
const StakeReport = Loadable(
  lazy(() => import("src/pages/userSide/financial/deposit/stake-report/index"))
);

const GiftReport = Loadable(
  lazy(() => import("src/pages/userSide/voucher/index"))
);

const financial = {
  path: "financial",
  children: [
    { index: true, element: <Navigate to="e-wallet" /> },
    { path: "e-wallet", element: <Ewallet /> },
    // {
    //   path: "deposit-wallet",
    //   children: [
    //     { index: true, element: <DepositWallet /> },
    //     { path: "add-credit", element: <AddCredit /> },
    //   ],
    // },
    { path: "funds-transfer", element: <FundTransfer /> },
    { path: "payout", element: <RequestPayout /> },
    { path: "stake-report", element: <StakeReport /> },
    { path: "gift-coupon", element: <CouponReport /> },

    { path: "gift", element: <GiftReport /> },
  ],
};

export default financial;
