import React from "react";
import { Card, CardContent, Typography, Grid, Box, Chip } from "@mui/material";
import useCoupon from "src/pages/settings/network/components/giftCoupon/hooks/useCoupon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const CouponCards = () => {
  const { state, handleUpdate, onSubmit } = useCoupon();
  const { data } = state;
  return (
    <Grid container spacing={5}>
      {data?.map((coupon) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={coupon.id}>
          <Card
            sx={{
              background: `linear-gradient(145deg, ${
                coupon.available_count > 0 ? "#d4f1f4" : "#e0e0e0"
              }, #ffffff)`,
              borderRadius: "15px",
              boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
              overflow: "hidden",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <Box
              sx={{
                background: coupon?.active
                  ? "linear-gradient(90deg, #4caf50, #81c784)"
                  : "linear-gradient(90deg, #9e9e9e, #cfcfcf)",
                color: "#fff",
                padding: "10px",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              ${coupon.amount}
            </Box>
            <CardContent
              sx={{
                textAlign: "center",
                // padding: "10px",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography
                color="text.primary"
                sx={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Total Coupons: {coupon.total_count}
              </Typography>
              <Typography
                color={
                  coupon.available_count > 0 ? "success.main" : "error.main"
                }
                sx={{ fontSize: "15px" }}
              >
                Available: {coupon.available_count}
              </Typography>
              {/* <Chip
                label={coupon.active ? "Active" : "Inactive"}
                color={coupon.active ? "success" : "default"}
                icon={
                  coupon.active ? <CheckCircleIcon /> : <ErrorOutlineIcon />
                }
                sx={{ fontSize: "0.9rem", fontWeight: "bold" }}
              /> */}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CouponCards;
