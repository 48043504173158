import { useEffect, useState } from "react";
import usePagination from "src/components/pagination/usePagination";
import useErrors from "src/hooks/useErrors";
import { incomeReportDefaultValues } from "src/pages/userSide/incomeReport/components/filter";
import axiosInstance from "src/utils/axios";

const useFetchCoupon = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(incomeReportDefaultValues);
  const handleErrors = useErrors();
  const { count, onChange, page, seed, rowStart } = usePagination();
  const fetchData = async (page = 1, filter = {}) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/admin/gift-coupon-report`,
        {
          params: {
            page,
            ...filter,
          },
        }
      );
      const { status, data: eWallet } = data;
      if (status) {
        seed(eWallet.last_page, eWallet.from);
        setData(eWallet.data);
      }
    } catch (err) {
      handleErrors(err);
    }
  };
  useEffect(() => {
    fetchData(page, filter);
  }, [page, filter]);

  return { data, fetchData, count, onChange, page, rowStart, setFilter };
};

export default useFetchCoupon;
