import { Box } from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import useLocales from "src/hooks/useLocales";
import { PATH_DASHBOARD } from "src/routes/paths";
import DataTable from "./components/dataTable";
import CouponCards from "./components/couponCard";

export default function CouponReport() {
  const { translate } = useLocales();

  return (
    <Page title={translate("Gift Coupon ")}>
      <Box sx={{ p: 2 }}>
        <HeaderBreadcrumbs
          heading={translate("Gift Coupon ")}
          links={[
            { name: translate("dashboard"), href: PATH_DASHBOARD.root },
            { name: translate("Gift Coupon ") },
          ]}
        />
        <CouponCards />
        <DataTable />
      </Box>
    </Page>
  );
}
