import { capitalCase } from "change-case";
import { RHFSelect } from "src/components/hook-form";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import Ternary from "../ternary";

const paymentTypes = [
  { value: "all", name: "All" },

  { value: "fund_transfer", name: "Transfer In" },

  {
    value: "stake_surrender",
    name: "Stake Surrender",
  },
  {
    value: "customer_incentive",
    name: "Customer Incentive",
  },

  {
    value: "partners_incentive",
    name: "Partners Incentive",
  },
  {
    value: "employee_incentive",
    name: "Employee Incentive",
  },
  {
    value: "partners_referral_bonus",
    name: "Partners Referral Bonus",
  },

  {
    value: "employee_referral_bonus",
    name: "Employee Referral Bonus",
  },
  {
    value: "bitz_club_reward",
    name: "Bitz Club Reward",
  },
];

const EWalletPaymentTypes = () => {
  const { translate } = useLocales();
  return (
    <>
      <>
        {" "}
        <RHFSelect
          name="payment_type"
          label={translate("sourceType")}
          size="small"
        >
          {paymentTypes.map(({ value, label, name }) => (
            <option value={value}>{name}</option>
          ))}
        </RHFSelect>
      </>
    </>
  );
};

export default EWalletPaymentTypes;
