import {
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import PaginationButtons from "src/components/pagination";

import { capitalCase } from "change-case";
import Scrollbar from "src/components/Scrollbar";
import ParseDate from "src/components/parse-date";
import useLocales from "src/hooks/useLocales";
import useFetchCoupon from "../hooks/useFetchCoupon";
import EmptyContent from "src/components/EmptyContent";
import Filter from "./filterCard";

const DataTable = () => {
  const { translate } = useLocales();
  const { data, fetchData, setFilter, rowStart, ...rest } = useFetchCoupon();
  return (
    <>
      <Card sx={{ mt: 2, p: 3 }}>
        <Filter setFilter={setFilter} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate("adminFinancial.Ewallet.no")}
                  </TableCell>
                  <TableCell>Account ID</TableCell>
                  <TableCell>{translate("Code")}</TableCell>
                  <TableCell>Coupon Batch</TableCell>

                  <TableCell>Coupon Amount</TableCell>
                  <TableCell>Coupon Status</TableCell>

                  <TableCell>
                    {translate("adminFinancial.Ewallet.date")}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <EmptyContent title="No Data Available" />
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <TableBody>
                {data.map((item, i) => (
                  <TableRow key={item.id}>
                    <TableCell>{i + rowStart}</TableCell>
                    <TableCell>{item?.user?.username}</TableCell>

                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item?.coupon_name}</TableCell>

                    <TableCell>{item?.coupon?.amount}</TableCell>
                    <TableCell>{capitalCase(item?.status || "")}</TableCell>

                    <TableCell>
                      <ParseDate>{item.created_at}</ParseDate>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      <PaginationButtons {...rest} />
    </>
  );
};

export default DataTable;
