import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import useLocales from "src/hooks/useLocales";
import { date, object, ref } from "yup";
import CustomRHFDatePicker from "src/pages/userSide/financial/ewallet/components/customRHFDatePicker.jsx";
import Users from "src/components/users";
import useAuth from "src/hooks/useAuth";
// import PaymentTypes from "./paymentTypes";

export const incomeReportDefaultValues = {
  start_date: null,
  end_date: null,
};

const getTomorrow = () => {
  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow;
};

const schema = object().shape({
  start_date: date()
    .max(getTomorrow(), "Future start date not allowed")
    .nullable(),
  end_date: date()
    .min(ref("start_date"), "end date can't be before start date")
    .nullable(),
});

const Filter = ({ setFilter, type }) => {
  const methods = useForm({
    defaultValues: incomeReportDefaultValues,
    resolver: yupResolver(schema),
  });
  const { translate } = useLocales();
  const onSubmit = (data) => {
    const { start_date, end_date, user_id, code, coupon_name, product_id } =
      data;
    setFilter({
      start_date:
        start_date && new Date(start_date).toLocaleDateString("en-GB"),
      end_date: end_date && new Date(end_date).toLocaleDateString("en-GB"),
      product_id: product_id,
      user_id: user_id,
      coupon_name: coupon_name,
      code: code,
    });
  };

  const { user } = useAuth();
  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1,1fr)",
            sm: "repeat(6, 1fr)",
          },
          rowGap: 3,
          columnGap: 3,
          marginBottom: "2rem",
        }}
      >
        <CustomRHFDatePicker
          label={translate("adminFinancial.payout.pickStartDate")}
          name="start_date"
          size="small"
        />
        <CustomRHFDatePicker
          label={translate("adminFinancial.payout.pickEndDate")}
          name="end_date"
          size="small"
        />
        <RHFTextField name="code" label="Coupon Code" size="small" />
        <RHFTextField name="coupon_name" label="Coupon Batch" size="small" />

        {/* <RHFSelect name="plan" label="Plan" size="small">
          <option value=""></option>
          <option value={"neo"}>Neo</option>
          <option value={"prime"}>Prime</option>
          <option value={"elite"}>Elite</option>
          <option value={"premium"}>Premium</option>
        </RHFSelect> */}
        <Button type="submit" variant="contained" size="medium">
          {translate("userFinancial.eWallet.getReport")}
        </Button>
      </Box>
    </FormProvider>
  );
};

export default Filter;
