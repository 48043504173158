import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { capitalCase } from "change-case";
import moment from "moment";
import EmptyContent from "src/components/EmptyContent";
import Scrollbar from "src/components/Scrollbar";
import ParseDate from "src/components/parse-date";
import useLocales from "src/hooks/useLocales";
import Filter from "./filter-card";
import useFetchCoupons from "./useFetchCoupons";
import axiosInstance from "src/utils/axios";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import PaginationButtons from "src/components/pagination";
import Ternary from "src/components/ternary";

const DataTable = () => {
  const { translate } = useLocales();
  const { data, rowStart, setFilter, fetchData, ...rest } = useFetchCoupons();

  const dateAndtime = (dates) => {
    const date = moment(dates);
    const formattedDate = date.format("DD/MM/YYYY");
    return <div>{formattedDate}</div>;
  };

  const isOlderThan365Days = (date) => {
    return moment().diff(moment(date), "days") > 365;
  };

  const calculateDaysLeft = (date) => {
    const daysLeft = 365 - date;
    return <>{daysLeft}</>;
  };

  return (
    <>
      <Card sx={{ mt: 2, p: 3 }}>
        <Filter setFilter={setFilter} type="investor" />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate("userFinancial.requestPayout.no")}
                  </TableCell>
                  <TableCell>Coupon Batch</TableCell>
                  <TableCell>Coupon Code</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              {data?.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <EmptyContent title="No Data Available" />
                  </TableCell>
                </TableRow>
              ) : (
                ""
              )}
              <TableBody>
                {data?.map((item, i) => {
                  return <Row item={item} rowStartNumber={i + rowStart} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
      <PaginationButtons {...rest} />
    </>
  );
};
const Row = ({ item, rowStartNumber }) => {
  return (
    <>
      <TableRow key={item.id}>
        <TableCell>{rowStartNumber}</TableCell>
        <TableCell>{item.coupon_name}</TableCell>
        <TableCell>{item.code}</TableCell>
        <TableCell>{item.coupon?.amount}</TableCell>
        <TableCell>{capitalCase(item.status || "")}</TableCell>
        <TableCell>
          <ParseDate>{item?.created_at}</ParseDate>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DataTable;
